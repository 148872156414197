html,
body {
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #FEFCFA;
  overflow-x: hidden;
}

@font-face {
  font-family: 'Quicksand';
  src: url('/public/fonts/Quicksand-Regular.ttf') format('truetype');
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color:black !important;
 
}

 
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color:#131313 !important;
  border-width:1px !important;
} 
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color:#131313 !important;
  border-width:1px !important;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
  /* height: 2.875rem !important; */
  border-radius: .25rem !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    /* height: 2.875rem !important; */
  border-radius: .25rem !important;
}
/* .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
  top: -3px !important;
} */
/* .ReactSelectFlags-module_container__SFuNT {
  width: 100% !important;
  height: 3.5rem !important;
 }
.ReactSelectFlags-module_searchSelect__O6Fp2{
  height: 3.5rem !important;
}
.ReactSelectFlags-module_selectWrapper__I7wcI{
  height: auto !important;
}
.ReactSelectFlags-module_optionText__s91tq{
  margin: 0 !important;
}
.ReactSelectFlags-module_searchSelect__O6Fp2{
color: rgba(0, 0, 0, 0.6) !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 1.4375em !important;
  opacity: 1 !important;
} */

.ReactFlagsSelect-module_flagsSelect__2pfa2{
  width: 100% !important;
 }
 .ReactFlagsSelect-module_selectBtn__19wW7{
   height: 3.5rem !important;
 }
 